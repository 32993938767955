module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://katahdin.lobstermarketing.com","noQueryString":true},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Demo Katahdin","short_name":"Demo Katahdin","start_url":"/","theme_color":"#fff","background_color":"#fff","display":"standalone","icon":"src/images/lobster512.png","include_favicon":false,"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"c511adc78a28d2f3f74ccefc7a0083f1"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-google-tagmanager-timeout/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PHR5M4C","includeInDevelopment":false,"timeout":5000,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}}},
    },{
      plugin: require('../../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"Home","exclude":["/dev-404-page/","/404/","/404.html","/offline-plugin-app-shell-fallback/"],"crumbLabelUpdates":[{"pathname":"/blog","crumbLabel":"Blog"},{"pathname":"/pest-library","crumbLabel":"Pest Library"},{"pathname":"/where-we-service","crumbLabel":"Where We Service"}]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-70},
    },{
      plugin: require('../../gatsby-theme-katahdin/gatsby-browser.js'),
      options: {"plugins":[],"projectId":"5c8fa56c-7155-00f7-dfe3-575ce9357b4f","authorizationKey":"ew0KICAiYWxnIjogIkhTMjU2IiwNCiAgInR5cCI6ICJKV1QiDQp9.ew0KICAianRpIjogImFkYWRlY2RjODNiNjQ1ZTg5NmRjYjNjMmRkODVlNDU2IiwNCiAgImlhdCI6ICIxNjIyMDUxMTcwIiwNCiAgImV4cCI6ICIxOTY3NjUxMTcwIiwNCiAgInByb2plY3RfaWQiOiAiNWM4ZmE1NmM3MTU1MDBmN2RmZTM1NzVjZTkzNTdiNGYiLA0KICAidmVyIjogIjEuMC4wIiwNCiAgImF1ZCI6ICJwcmV2aWV3LmRlbGl2ZXIua2VudGljb2Nsb3VkLmNvbSINCn0.EapyjdZtpmrvfhF6g5BNpr5wnXtBIqSdEtvFmwZYggQ","usePreviewUrl":"false","whereWeServicePathName":"where-we-service","whereWeServiceBreadcrumb":"Where We Service","wWSActive":"0","siteUrl":"https://katahdin.lobstermarketing.com","title":"Demo Katahdin","description":"FieldRoutes","domainVerification":"meta-tag","gtmId":"GTM-PHR5M4C","compassID":"42a82a20fd60882a6cf4067db94368738701fce0d5ce61ec6c4845b74d313fbc","recaptchaSiteKey":"6LeH19ohAAAAAGOJT48v4ptW-h_xsld_xxhZfawg","recaptchaSecretKey":"6LeH19ohAAAAAM4YU4Y0cp0BTW_IpF4Uw_D94vvM","pestLibraryPathName":"pest-library","pestLibraryBreadcrumb":"Pest Library","pestLibraryActive":"0"},
    },{
      plugin: require('../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
